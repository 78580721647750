<template>
    <div class="form-row" v-if="supplier">
        <div class="col-md-12">
            <br>
            <div class="form-row d-flex justify-content-center mb-2">
                <label class="text-uppercase"><b>{{supplier.Supplier.Name}}</b></label>
            </div>
            <div class="form-row col-md-12 border-bottom">
                <div class="col-md-6 section-title">
                    <label>{{tr('Services')}}</label>
                </div>
                <div class="col-md-2 text-right">
                    <span>{{tr('Cost')}}</span>
                </div>
                <div class="col-md-2">
                    <span class="text-right">{{tr('Status')}}</span>
                </div>
                <div class="col-md-2">
                    <span class="text-right">{{tr('Reservation')}}</span>
                </div>
            </div>
            <div v-for="(service, idx) of supplier.Services" v-if="service.Quote" class="form-material">
                <div class="form-row col-md-12 d-flex align-items-center border-bottom" :class="getBGClass(idx)">
                    <div class="col-md-6 my-0">
                        <span>{{formatDate(service.ServiceDate)}}</span>&nbsp;
                        <span>
                            {{service.Name || service.Service.Name}}
                            {{getCategoryName(service)}}
                        </span>
                        <span v-if="getServiceTypeName(service)">
                          &nbsp;({{getServiceTypeName(service)}})
                        </span>
                    </div>
                    <div class="form-group col-md-2 my-0 d-flex justify-content-end">
                        <span> {{service.serviceTotal.Cost | formatNumber({c: recordStore.Currency})}} {{recordStore.CurrencyId}}</span>
                    </div>
                    <div class="col-md-2 my-0">
                        <select-input fieldName="Status"
                        :field="service.fieldsObject.Status" label=""
                        class="my-0"
                        :currentValue.sync="service.Status" :required="true" :notSort="true"
                        ></select-input>
                    </div>
                    <div class="form-group col-md-2 form-default my-0">
                        <input
                            v-model="service.ReservationNumber"
                            class="form-control"
                            :class="{'fill': service.ReservationNumber}"
                            type="text" maxlength="50">
                                <span class="form-bar"></span>
                        </input>
                    </div>
                </div>
            </div>
            <div class="form-row border-bottom col-md-12 form-material d-flex align-items-center font-weight-bold" v-if="supplier.Services.length>0">
                <div class="form-group col-md-6 my-1">
                    <label>{{tr('Total')}}</label>
                </div>
                <div class="form-group col-md-2 my-0 d-flex justify-content-end">
                    <span>{{supplier.Totals.Cost | formatNumber({c: recordStore.Currency})}} {{recordStore.CurrencyId}}</span>
                </div>
                <div class="form-group col-md-2 my-0">
                    <select-input fieldName="Status"
                    :field="supplier.Services[0].fieldsObject.Status" label=""
                    class="my-0"
                    :currentValue.sync="serviceStatus" @change="setStatus($event, supplier.Services)"></select-input>
                </div>
            </div>
        </div>
        <div class="form-row col-md-12 mt-4 desktop">
            <div class="section-title mx-4">
                <label class="my-0">{{tr('Request Services')}}</label>
            </div>
            <div class="col">
                <button type="button" class="btn btn-primary action-button my-0" @click="sendServiceEmail(supplier.Supplier)"
                    rel="tooltip" data-placement="top" title="Email">
                    <font-awesome-icon icon="envelope"/>
                </button>
            </div>
            <div class="col">
                <input v-model="includeFlights"  class="form-check-input" type="checkbox" name="includeFlights">
                <label class="smaller form-check-label">{{tr('Flights')}}</label>
            </div>
            <div class="col">
                <input v-model="includeHotels"  class="form-check-input" type="checkbox" name="includeHotels">
                <label class="smaller form-check-label">{{tr('Hotels')}}</label>
            </div>
            <div class="col">
                <input v-model="includePickup"  class="form-check-input" type="checkbox" name="includePickup">
                <label class="smaller form-check-label">{{tr('Pickup')}}</label>
            </div>
        </div>
        <div class="col-md-12">
            <booking-payments
                :supplierId="supplier.Supplier.id"
                :supplierData="supplier"
            ></booking-payments>
        </div>
        <booking-operations-notes :supplier.sync="supplier">
        </booking-operations-notes>
        <attach
          title="Documents"
          :dataList.sync="recordStore.attachList"
          :attached.sync="recordStore.attached"
          :id="recordStore.id"
          :folder="'supplier/' + supplier.Supplier.id + '/'"
          :processing="recordStore.processingAttach"
          :canAttach="true"
          :canDelete="true"
          v-if="recordStore.attached"
        ></attach>
        <slot name="custom"></slot>
    </div>
</template>

<script>
const bookingPayments = importVueComp('components/booking', 'BookingPayments');
const bookingOperationsNotes = importVueComp('components/booking', 'BookingOperationsNotes');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'operations-service',
    components: {
        'booking-payments': bookingPayments,
        'booking-operations-notes': bookingOperationsNotes,
    },
    props: ['supplier'],
    data () {
        return {
            loaded: false,
            includeHotels: null,
            includeFlights: null,
            serviceStatus: null,
            includePickup: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            bookingForm: state => state.booking.bookingForm,
        }),
    },
    watch: {
        supplier  (){
            this.serviceStatus = null;
        }
    },
    methods: {
        async sendServiceEmail () {
            if (!this.bookingForm) {
                alert(tr('There is not reservation form'));
                return;
            }
            let mailRecord = await this.recordStore.createSupplierServiceMail(this.bookingForm, this.user,
                this.supplier, this.includeHotels, this.includeFlights, this.includePickup);
            await this.$nextTick();
            EventBus.$emit('open-modal-record', {modalRecord: mailRecord, modalTable: 'mail', modalId: 'new'});
        },
        async setStatus (value, services) {
            await this.$nextTick();
            this.recordStore.setServiceStatus(value, services);
        },
        getCategoryName (s) {
            if (s.ServiceCategory && s.ServiceCategory.Name) return s.ServiceCategory.Name;
            return '';
        },
        getServiceTypeName (s) {
            if (s.Service && s.Service.ServiceType && s.Service.ServiceType.Name) return s.Service.ServiceType.Name;
            return '';
        },
        formatDate (d) {
            return moment(d, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
        getBGClass (idx) {
            if (idx % 2 == 0) return 'bg-silver';
            return '';
        }

    },
}
</script>
