<template>
    <div id="app">
        <div v-if="!public && loaded && user && user.id" class="app-index">
            <div :class="{'wrapper': !fullScreen}" v-if="!public && loaded && user.id">
                <nav-bar v-show="!fullScreen"></nav-bar>
                <side-bar v-if="!public && loaded && user.id" v-show="!fullScreen"></side-bar>
                <div id="content">
                    <div class="row alert alert-warning py-1" role="alert" v-if="appWarning">
                         {{ appWarning }}
                    </div>
                    <div class="row">
                        <div class="col-12" :class="{'px-3 pt-2': !fullScreen, 'px-0': fullScreen}">
                            <router-view :key="$route.fullPath" v-if="loaded">
                            </router-view>
                        </div>
                    </div>
                </div>
                <record-window-modal
                    v-if="showModalRecord"
                    :showRecordModal.sync="showModalRecord"
                    :newRecord="modalRecord"
                    :modalLarge="true"
                    :table="modalTable"
                    :id="modalId"
                    :view="view"
                    @close="closeModal"
                ></record-window-modal>
                <div class="minmax-con display-none px-2" @click="maximize"> <b>{{modalMinTitle}}</b>
                    <font-awesome-icon icon="window-maximize" class="ml-2 mt-1 cursor-pointer"  style="float: right"/>
                </div>
                <component :is="comp.name"
                    v-if="showModal"
                    :ref="comp.ref"
                    :show.sync="showModal"
                ></component>
                <select-value
                    v-if="selectValue"
                    @save="saveSelectValue"
                    :show.sync="selectValue"
                    :text="selectValue.text"
                    :fieldOptions="selectValue.options"
                    :defValue="selectValue.defValue"
                ></select-value>
                <input-value
                    :type="inputValue.type"
                    v-if="inputValue"
                    :disabled="inputValue.disabled"
                    @save="saveInputValue"
                    @close="closeInputValue"
                    :def="inputValue.value"
                    :rows="inputValue.rows"
                    :text="inputValue.text">
                </input-value>

            </div>
        </div>
        <div v-else>
            <router-view :key="$route.fullPath" v-if="loaded">
            </router-view>
        </div>
        <block-ui v-if="processing || !loaded" :message="loadingMessage" ref="blockUI"/>
        <scroll-up></scroll-up>
        <alert-popup
            v-if="showAlert"
            @close="closeAlert"
        ></alert-popup>
        <context-menu
            class="app-index"
            :options="langOptions"
            ref="translateTo"
            :showInput="true"
            @clicked="menuClicked"
        ></context-menu>
        <yes-not
            v-if="selectYesNot"
            @yes="selectYes"
            @not="selectNot"
            :show.sync="selectYesNot"
            :text="selectYesNot.text">
        </yes-not>
        <!--in-app pos="right" ref="inApp"></in-app-->
    </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { mapState } from "vuex";
import scrollUp from '@/components/tools/ScrollUp';
var EventBus = require('@/tools/event-bus').default;
let sl = require('@/languages/system_languages.json');
var languages = {};
for (let langCode in sl) {
    var lang = frontTools.importJSON(langCode + '.json', 'languages');
    if (!lang) lang = {}
    languages[langCode] = Object.assign(sl[langCode], lang);
}

export default {
    name: "App",
    components: {
      'scroll-up': scrollUp
    },
    data () {
        return {
            public: false,
            loaded: false,
            aliveTime: 1000 * 60 * 5,
            aliveStatus: false,
            bkpTime: 1000 * 60 * 5,
            bkpStatus: false,
            showAlert: false,
            showModalRecord: false,
            modalRecord: null,
            modalTable: null,
            modalId: null,
            closeAction: null,
            closeMethod: null,
            comp: null,
            view: null,
            langOptions: [],
            comp: {},
            showModal: false,
            selectYesNot: null,
            selectValue: null,
            inputValue: null,
            appWarning: null,
            inrerval: null,
            loadingMessage: null,
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            fullScreen: state => state.main.fullScreen,
            processing: state => state.main.processing,
            quillFocus: state => state.quill.quillFocus,
            quillStatus: state => state.quill.quillStatus,
            modalMinTitle: state => state.main.modalMinTitle,
        }),
        modified () {
            if (this.$store.getters.modified) {
                return true;
            }
        },
    },
    async created () {
        this.public = this.$router.currentRoute.meta.public;
        if (process.env.NODE_ENV=='production'){
            /*Sentry.init(
                {
                    //dsn: 'https://c12655a06f84415d87f5c981c7b289b0@sentry.io/1827065',
                    dsn: 'https://e801e80686414a4d987c1a717ec81b51@sentry.io/4505127351681024',
                    environment: process.env.NODE_ENV,
                    integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
                    beforeSend: (event, hint) => {
                        setTimeout(() => {
                            console.error(hint.originalException || hint.syntheticException)
                            let err = hint.originalException || hint.syntheticException;
                            if (err) console.logs.push([err.message, err.stack]);
                        }, 0);
                        return event;
                    },
                    release: 'nubbo-master'
                }
            );*/
        }
        if (window.innerWidth < 500) {
            this.$store.commit('setMobile', true);
        }
        if (!this.public) {
            let remove = false;
            if (remove) {
                var req = indexedDB.deleteDatabase("nubbo-app");
                req.onsuccess = () => {
                    console.log("Deleted database successfully");
                };
                req.onerror = () => {
                    console.log("database not deleted");
                };
            }

            let nubbo = indexedDB.open("nubbo-app");
            nubbo.onupgradeneeded = () => {
                let db = nubbo.result;
                db.createObjectStore("bookingBkp", {keyPath: "id"});
                db.close();
            };

        }
        window.onscroll = function (e) {
            $.each($(".notifications"),function() {
                let notPos;
                let notif = $(this);
                if (notif) {
                    notPos = notif.position();
                }
                let scroll;
                let modal = $('.modal');
                if (modal) {
                    scroll = $('.modal').scrollTop();
                }
                if (!scroll) {
                    scroll = $(document).scrollTop();
                }
                                if (notPos && notPos && notPos.top < scroll) {
                    if (!notif.hasClass("not-float")) notif.addClass("not-float");
                } else {
                    if (notif.hasClass("not-float")) notif.removeClass("not-float");
                }
            });
        }

        this.setLanguage();
        const tr = (text, lang) => {
            if (!text) return text;
            if (typeof text === 'number') return text;
            //if (typeof text === 'object') return text;
            let l = lang;
            if (!l) l = api.language;
            try {
                if (!l) l = window.lang;
                if (Array.isArray(text)) {
                    let r = '';
                    for (let v of text) {
                        let t = v;
                        if (typeof v === 'object') {
                            t = tr(v.errorCode);
                        }
                        r += t + ' ';
                    }
                    return r;
                }
                if (text in languages[l]) return languages[l][text];
                if (typeof text === 'string') {
                    if (text.toUpperCase() in languages[l]) return languages[l][text.toUpperCase()];
                    if (tools.toTitleCase(text) in languages[l]) return languages[l][tools.toTitleCase(text)];
                }
            } catch (err) {
                //console.log(err)
            }
            return text
        }
        window.tr = tr;
        Vue.mixin({
            methods: {
                tr: tr
            }}
        )
        await tools.fetchTable('language');
        if (api.tables.language) {
            for (let langCode in languages) {
                var lang = _.find(api.tables.language, (r) => r.Code == langCode);
                if (lang && lang.Translations) {
                    let translation = lang.Translations;
                    languages[langCode] = Object.assign(translation, languages[langCode]);
                }
            }
        }
        if (!api.currentUser) {
            //  require('@/tools/cookieconsent');
        }
    },
    async mounted () {
        this.inrerval = setInterval(() => {
            if (api.processing!=vueTools.processing) {
                vueTools.setProcessing(api.processing)
            }
            if (api.showAlert) {
                this.showAlert = api.showAlert;
            } else {
                this.showAlert = false;
            }
            if (api.selectYesNot && !this.selectYesNot) {
                this.selectYesNot = api.selectYesNot;
            }

        }, 100);
        let self = this;
        document.addEventListener('click', function(event) {
            if (!self.aliveStatus && self.user && self.user.UserName) {
                self.aliveStatus = true;
                api.get('/api/keep_alive/');
                setTimeout(() => {
                    self.aliveStatus = false;
                }, self.aliveTime)
            }

            if (!self.bkpStatus && self.user && self.user.UserName) {
                self.bkpStatus = true;
                EventBus.$emit('bkp-booking');
                setTimeout(() => {
                    self.bkpStatus = false;
                }, self.bkpTime)
            }

            let specifiedElement1 = document.getElementById(self.quillFocus);
            let isClickInside1;

            if (specifiedElement1) {
                isClickInside1 = specifiedElement1.contains(event.target);
            }
            if (!isClickInside1) {
                if (!self.quillFocus && !self.quillStatus) return;
                self.$store.commit('setQuillStatus', false);
                setTimeout(function() {
                    self.$store.commit('setQuillFocus', false);
                }, 50);
            }
        });
        window.onbeforeunload = function (e) {
            if (self.modified) {
                e = e || window.event;
                if (e) {
                    e.returnValue = 'Sure?';
                }
                return 'Sure?';
            }
        };
        let loadTables = vueTools.importModule('load-tables', 'tools').default;
        let l = new loadTables(this.$router.currentRoute);
        await l.getPromises()
        EventBus.$on('open-modal-record', this.openModalRecord);
        //EventBus.$on('open-modal', this.openModal);
        //EventBus.$on('handle-click', this.handleClick);
        //EventBus.$on('open-select-value', this.openSelectValue);
        //EventBus.$on('open-select-yest-not', this.openSelectYesNot);
        //l.load();
        this.loaded = true;
    },
    watch: {
      $route(to) {
        if (to.meta.icon) {
          const link = document.querySelector("[rel='icon']")
          link.setAttribute('href', to.meta.icon)
        }
      }
    },
    methods: {
        openModalRecord ({modalRecord, modalTable, modalId, closeAction, view, closeMethod, comp}) {
            this.modalRecord = modalRecord;
            this.modalTable = modalTable;
            this.modalId = modalId;
            this.closeAction = closeAction;
            this.showModalRecord = true;
            this.view = view;
            this.comp = comp;
            this.closeMethod = closeMethod;
        },
        openModal (comp) {
            this.comp = Object.assign({}, comp);
            this.showModal = true;
        },
        closeModal () {
            this.modalRecord = null;
            this.modalTable = null;
            this.modalId = null;
            this.showModalRecord = null;
            this.view = null;
            if (this.closeAction) EventBus.$emit(this.closeAction);
            if (this.comp && this.closeMethod) this.comp[this.closeMethod]();
            this.comp = null;
            this.closeMethod = null;
            this.closeAction = null;
            this.$store.commit('initRecord', {record: null, modal: true});
        },
        setLanguage(lang) {
            if (!lang) {
                let navLang = (navigator.userLanguage || navigator.language).substring(0,2)
                if (navLang in languages) {
                    lang = navLang
                } else {
                    lang = 'en';
                }
            }
            window.lang = lang;
            this.lang = lang;
        },
        closeAlert () {
            this.showAlert = false;
            api.showAlert = null;
        },
        maximize () {
            $('#record-window-modal').toggleClass("display-none");
            $(".modal-backdrop").toggleClass("display-none");
            $(".minmax-con").toggleClass("display-none");
            $("body").toggleClass("modal-open");
        },
        async menuClicked (option, selection, event, text) {
            if (option.name && selection && text) {
                let data = {
                    id: option.name,
                    translateFrom: selection,
                    translateTo: text
                }
                let res = await api.post('/api/language/translate', JSON.stringify(data));
                if (res) {
                    console.log(res)
                }
            }
        },
        handleClick (event) {
            if (!api.currentUser) return;
            if (!api.currentUser.id) return;
            let selection = this.getSelectionText();
            if (selection.length>0) {
                event.preventDefault()
                this.langOptions = [{label: tr('Translate to'), 'class': 'border-bottom mb-1'}];
                for (let lang of api.tables.language) {
                    this.langOptions.push({label: lang.Code, name: lang.id})
                }
                this.langOptions = Object.assign([], this.langOptions);
                this.$refs.translateTo.showMenu(event, selection)
            }
        },
        getSelectionText () {
            var text = "";
            if (window.getSelection) {
                text = window.getSelection().toString();
            } else if (document.selection && document.selection.type != "Control") {
                text = document.selection.createRange().text;
            }
            return text;
        },
        async selectYes () {
            await this.selectYesNot.yes()
            this.selectYesNot = null;
            api.selectYesNot = null;
        },
        async selectNot () {
            await this.selectYesNot.not()
            this.selectYesNot = null;
            api.selectYesNot = null;
        },
        openSelectValue (obj) {
            this.selectValue = obj;
        },
        openSelectYesNot (obj) {
            this.selectYesNot = obj;
        },
        async saveSelectValue (value) {
            await this.selectValue.save(value);
            this.selectValue = null;
        },
        openInputValue (obj) {
            this.inputValue = obj;
        },
        async saveInputValue (value) {
            await this.inputValue.save(value);
            this.inputValue = null;
        },
        async closeInputValue () {
            await this.inputValue.close();
            this.inputValue = null;
        }
    },
    beforeDestroy () {
        clearInterval(this.interval);
    },

};
</script>
