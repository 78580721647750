<template>
    <div class="modal fade bd-example-modal-lg" id="service-cost-modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-large" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="card-body pt-3 mb-1">
                <div class="form-material form-row">
                    <div class="form-group col-md-4 row my-0">
                        <div class="col-6">
                            <date-picker id="ServiceCost-StartDate"
                                :label="'Period'"
                                fieldName="StartDate"
                                :def.sync="period.StartDate"
                                @change="setRowsModified"
                            ></date-picker>
                        </div>
                        <div class="col-6" style="padding-left: 0px">
                            <date-picker id="ServiceCost-EndDate"
                                :def.sync="period.EndDate"
                                :startDate="period.StartDate"
                                fieldName="EndDate"
                                @change="setRowsModified"
                            ></date-picker>
                        </div>
                    </div>
                    <div class="form-group col-md-4 my-0">
                      <input-field
                        id="Comment"
                        :defValue.sync="period.Comment"
                        @change="commentChange"
                        :field="{name: 'Comment', label: 'Comment', editor: 'text'}"
                      ></input-field>
                    </div>
                    <div class="form-group col-md-2 my-0">
                        <div class="form-check mt-3 ml-3">
                            <input v-model="period.BlackOut" name="BlackOut"
                                @change="setBlackout"
                                class="form-check-input"
                                type="checkbox">
                            <label class="smaller form-check-label">Black Out</label>
                        </div>
                    </div>
                    <div class="form-group col-md-2 my-0">
                       <input-field
                        id="ChildDiscount"
                        :defValue.sync="period.ChildDiscount"
                        @change="setRowsModified"
                        :field="{name: 'ChildDiscount', label: 'Child Discount', editor: 'number'}"
                      ></input-field>
                    </div>
                    <div class="form-group col-md-2 my-0">
                       <input-field
                        id="ChildDiscount"
                        :defValue.sync="period.Commission"
                        @change="setRowsModified"
                        :field="{name: 'Commission', label: 'Commission', editor: 'number', d: 0}"
                      ></input-field>
                    </div>
                    <div class="form-group col-md-2 my-0">
                        <div class="form-check mt-3 ml-3">
                            <input v-model="period.TourLeader" name="TourLeader"
                                @change="setTourLeader"
                                class="form-check-input"
                                type="checkbox">
                            <label class="smaller form-check-label">Tour Leader</label>
                        </div>
                    </div>
                    <div class="form-group col-md-8 my-0 pl-3">
                        <record-week-days
                            class=""
                            :record.sync="period"
                            :emits="false"
                            @change="setRowsModified"
                        ></record-week-days>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-12 form-row mb-3">
                        <button type="button" class="btn btn-secondary col"
                            :class="{'icon-active': newBase}"
                            @click="newBlaseClick">
                            {{tr('Add Base')}}
                        </button>
                        <button type="button" class="btn btn-secondary col ml-2"
                            :class="{'icon-active': editBase}"
                            @click="editBaseClick">
                            {{tr('Edit Base')}}
                        </button>
                        <button type="button" class="btn btn-secondary col ml-2"
                            :class="{'icon-active': newService}"
                            @click="newServiceClick">
                            {{tr('Add Service')}}
                        </button>
                        <button type="button" v-if="hasCategories" class="btn col btn-secondary ml-2"
                            :class="{'icon-active': selectCategory}"
                            @click="selectCategoryClick">
                            {{tr('Add Category')}}
                        </button>
                        <button type="button" class="btn btn-secondary col ml-2"
                            :class="{'icon-active': increase}"
                            @click="applyIncrease">
                            {{tr('Apply Increase')}}
                        </button>
                        <button type="button" v-if="hasCategories" class="btn btn-secondary col ml-2"
                            @click="removeCategories">
                            {{tr('Remove Categories')}}
                        </button>
                    </div>
                    <div v-if="newBase || editBase" class="form-material form-row col-12">
                          <select-input
                            v-if="editBase"
                            fieldName="baseToEdit"
                            label="Replace"
                            class="col-2 my-0"
                            :fieldOptions="fieldOptions.baseToEdit"
                            :currentValue.sync="baseToEdit"
                          ></select-input>
                          <div class="col-1 form-group form-default my-0" v-if="!extraPax">
                            <input v-model="fromValue" type="number" class="form-control" style="text-align:right;">
                                <span class="form-bar">
                                </span>
                                <label class="float-label">{{tr('From')}}</label>
                            </input>
                          </div>
                          <div class="col-1 form-group form-default my-0" v-if="!extraPax">
                            <input v-model="toValue" type="number" class="form-control" style="text-align:right;">
                                <span class="form-bar">
                                </span>
                                <label class="float-label">{{tr('To')}}</label>
                            </input>
                          </div>
                          <div class="col-2 form-group form-default my-0 text-center">
                              <div class="form-check">
                                  <input v-model="extraPax" name="extraPax"
                                      class="form-check-input"
                                      type="checkbox">
                                  <label class="smaller form-check-label">{{tr('Extra Pax')}}</label>
                              </div>
                          </div>
                        <div class="col-3 pl-1 pr-0" :class="{'offset-2': !editBase}">
                            <button type="button" class="btn btn-secondary w-100" @click="cancelNewBase">
                              {{tr('Cancel')}}
                            </button>
                        </div>
                        <div class="col-3 pl-1 pr-0">
                            <button type="button" class="btn btn-outline-secondary w-100" @click="addBase" :disabled="!fromValue && !extraPax">
                              {{tr('Done')}}
                            </button>
                        </div>
                    </div>
                    <div class="col-12 form-material form-row" v-if="newService">
                        <vue-select-input
                          fieldName="serviceId"
                          label="Service"
                          class="col-4 my-0"
                          :fieldOptions="fieldOptions.ServiceId"
                          :currentValue.sync="serviceId"
                        ></vue-select-input>
                        <div class="col-2 form-group form-default my-0 pl-4">
                            <div class="form-check">
                                <input v-model="onlyPending" name="onlyPending"
                                    class="form-check-input"
                                    type="checkbox">
                                <label class="smaller form-check-label">{{tr('Only not added')}}</label>
                            </div>
                        </div>
                        <div class="col-3 pl-1 pr-0">
                            <button type="button" class="btn btn-secondary w-100" @click="newService = false">{{tr('Cancel')}}</button>
                        </div>
                        <div class="col-3 pl-1 pr-0">
                            <button type="button" class="btn btn-outline-secondary w-100" @click="add">{{tr('Done')}}</button>
                        </div>
                    </div>
                    <div class="col-12 form-material form-row" v-if="increase">
                        <number-input
                            label="%"
                            class="my-0 col-2"
                            :v.sync="increaseValue"
                            :d="2"
                        ></number-input>
                        <div class="offset-4 col-3 pl-1 pr-0">
                            <button type="button" class="btn btn-secondary w-100" @click="increase = false">{{tr('Cancel')}}</button>
                        </div>
                        <div class="col-3 pl-1 pr-0">
                            <button type="button" class="btn btn-outline-secondary w-100" @click="setIncrease">{{tr('Done')}}</button>
                        </div>
                    </div>
                    <div class="col-12 form-material form-row" v-if="selectCategory">
                        <select-input fieldName="categoryId" :fieldOptions="fieldOptions.CategoryId"
                            class="col-6 my-0" label="Category"
                            :currentValue.sync="categoryId"></select-input>
                        <div class="offset-4 col-3 pl-1 pr-0">
                            <button type="button" class="btn btn-secondary w-100" @click="selectCategory = false">{{tr('Cancel')}}</button>
                        </div>
                        <div class="col-3 pl-1 pr-0">
                            <button type="button" class="btn btn-outline-secondary w-100" @click="setCategory">{{tr('Done')}}</button>
                        </div>
                    </div>
                    <div class="col-12 form-row alert" v-if="error" :class="{'alert-danger': danger, 'alert-warning': !danger}">
                        {{error}}
                    </div>

                </div>
                <table-editor class="mt-4" :table.sync="period.table"
                :currency="period.CurrencyId"
                @changed="changed"
                    @removeBase="removeBase"
                    @removeAll="removeAll"
                    @removeEmpty="removeEmpty"
                    @remove="remove">
                </table-editor>
                <div class="form-group col-md-2 my-0">
                    <div class="form-check mt-3 ml-3">
                        <input v-model="period.Closed" name="Closed"
                                @change="setClosed"
                                class="form-check-input"
                                type="checkbox">
                        <label class="smaller form-check-label">{{tr('Archive')}}</label>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const tableEditor = importVueComp('components/tools', 'TableEditor');
const escape = importVueComp('components/tools', 'Escape');
const recordWeekDays = importVueComp('components/tools', 'RecordWeekDays');
import { mapState } from "vuex";
//const moment = require("moment");
export default {
    name: 'supplier-service-cost',
    mixins: [escape],
    props: ["record", 'period'],
    components: {
        'table-editor': tableEditor,
        'record-week-days': recordWeekDays,
    },
    data () {
        return {
            includeClosed: false,
            services: null,
            newService: false,
            newBase: false,
            editBase: false,
            selectCategory: false,
            newPeriodCategory: null,
            fromValue: null,
            toValue: null,
            serviceId: null,
            closed:null,
            categoryId: null,
            categoryName: null,
            extraPax: false,
            increase: false,
            increaseValue: null,
            baseToEdit: null,
            onlyPending: true,
            error: null,
            danger: false,
            CostByOptions: [
                {value: 'BASE_PERSON', label: 'Per Person, variable on base'},
                {value: 'PERSON', label: 'Per Person, fixed'},
                {value: 'SERVICE', label: 'Per Service, fixed'},
                {value: 'BASE_SERVICE', label: 'Per Service, variable on base'}
            ]
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            processing: state => state.main.processing,
        }),
        fieldOptions () {
            let res = {ServiceId: [], CategoryId: [], baseToRemove: [], baseToEdit: []};
            if (this.services) {
                for (let s of this.services) {
                    let added = _.find(this.period.table.rows, (r) => {
                        return r.ServiceId.value == s.id;
                    });
                    if (added && this.onlyPending) continue;
                    res.ServiceId.push({label: s.Name, value: s.id})
                    if (s.ServiceCategories) {
                        for (let c of s.ServiceCategories) {
                            let f = _.find(res.CategoryId, (r) => r.label == c.Name);
                            if (!f) res.CategoryId.push({ label: c.Name, value: c.id });
                        }
                    }
                }
            }
            if (this.record.ServiceCategories) {
                if (this.record.ServiceCategories) {
                    for (let c of this.record.ServiceCategories) {
                        res.CategoryId.push({ label: c.Name, value: c.id });
                    }
                }
            }
            let add;
            for (let value of this.period.table.header) {
                if (add) {
                    res.baseToEdit.push({label: value.value, value: value.value})
                }
                if (value.value == 'CHD') add = true;
            }
            return res;
        },
        currencyList () {
            if (!api.tables.currency) return [];
            let res = [];
            for (let c of api.tables.currency) {
                res.push({ label: c.id, value: c.id });
            }
            return res;
        },
        hasCategories () {
            if (
                _.filter(this.record.ServiceCategories, c => c.Closed != true)
                    .length > 0
            ) {
                return true;
            }
            if (this.services) {
                for (let s of this.services) {
                    if (
                        _.filter(s.ServiceCategories, c => c.Closed != true)
                            .length > 0
                    ) {
                        return true;
                    }
                }
            }
            return false;
        },
        async costBy () {
            let res = {};
            let servicetypes = await api.getTable('servicetype');
            for (let s of servicetypes) {
                res[s.Name] = s.CostBy;
            }
            return res;
        },
    },
    async mounted () {
        $('#service-cost-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        if (!this.record.id) return;
        api.setProcessing(true)
        let promises = [];
        promises.push(this.getServices());
        await Promise.all(promises);
        api.setProcessing(false)
        $('input[name="Comment"]').on('keypress', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9 .-]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
               event.preventDefault();
               return false;
            }
        });
    },
    methods: {
        newBlaseClick () {
            this.newBase = !this.newBase;
            this.newService = false;
            this.selectCategory = false;
            this.editBase = false;
            this.increase = false;
        },
        editBaseClick () {
            this.editBase = !this.editBase;
            this.newBase = false;
            this.newService = false;
            this.selectCategory = false;
            this.increase = false;
        },
        newServiceClick () {
            this.newService = !this.newService;
            this.editBase = false;
            this.newBase = false;
            this.selectCategory = false;
            this.increase = false;
        },
        selectCategoryClick () {
            this.selectCategory = !this.selectCategory;
            this.editBase = false;
            this.newBase = false;
            this.newService = false;
            this.increase = false;
        },
        applyIncrease () {
            this.increase = !this.increase;
            this.editBase = false;
            this.newBase = false;
            this.newService = false;
            this.selectCategory = false;
        },
        add () {
            let service = _.find(this.services, (s) => s.id == this.serviceId);
            if (service) {
                let serviceClass = _.find(api.tables.serviceclass, (r) => r.id == service.ServiceClassId);
                let serviceType = _.find(api.tables.servicetype, (r) => r.id == service.ServiceTypeId);
                let data = {
                    name: service.Name,
                    id: service.id,
                    CurrencyId: service.CurrencyId,
                    CostBy: service.CostBy,
                }
                if (!data.CostBy) data.CostBy = service.ServiceType.CostBy;
                if (serviceClass) data.ServiceClass = serviceClass.Name;
                if (serviceType) data.type = serviceType.Name;
                this.addService(data);
            }
        },
        async setCategory () {
            let c = _.find(this.fieldOptions.CategoryId, (r) => r.value == this.categoryId);
            if (c) this.categoryName = c.label;
            this.selectCategory = false;
            await this.addServicesToPeriod(
                this.period,
                this.categoryId
            );
            this.period.table = Object.assign(
                {},
                this.period.table
            );
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        async addServicesToPeriod (period, categoryId) {
            if (!this.services) {
                await this.getServices();
            }
            if (!this.services) return;
            for (let r of this.services) {
                let row = { values: [] };
                row.id = {
                    fieldName: "id",
                    value: null,
                    editable: false,
                    hidden: true
                };
                row.Closed = {
                    fieldName: "Closed",
                    value: null,
                    editable: false,
                    hidden: true
                };
                row.syncVersion = {
                    fieldName: "syncVersion",
                    value: null,
                    editable: false,
                    hidden: true
                };
                row.values.push({
                    fieldName: "Name",
                    value: r.Name,
                    editable: false,
                    width: "300px",
                    sort: -4,
                    className: 'width-200'
                });
                if (r.ServiceType) {
                    row.values.push({
                        fieldName: "ServiceType",
                        value: r.ServiceType.Name,
                        editable: false,
                        sort: -3
                    });
                }
                row.ServiceId = {
                    fieldName: "ServiceId",
                    value: r.id,
                    editable: false,
                    hidden: true
                };
                if (this.hasCategories) {
                    let cId = categoryId;
                    if (r.ServiceCategories) {
                        let found = _.find(r.ServiceCategories, (n) => n.id == categoryId);
                        if (!found) {
                            let c = _.find(r.ServiceCategories, (n) => n.Name == this.categoryName);
                            if (c) cId = c.id;
                        }
                    }
                    row.values.push({
                        fieldName: "ServiceCategoryId",
                        value: cId,
                        editable: true,
                        editor: "select",
                        addBlank: true,
                        fieldOptions: {
                            ServiceCategoryId: this.getCategories(r.id)
                        },
                        sort: -2
                    });
                }
                row.values.push({
                    fieldName: "CurrencyId",
                    value: r.CurrencyId,
                    editable: true,
                    sort: -1,
                    editor: "select",
                    fieldOptions: { CurrencyId: this.currencyList }
                });
                row.values.push({
                    fieldName: "CostBy",
                    value: r.CostBy,
                    editable: true,
                    sort: -1.5,
                    editor: "select",
                    fieldOptions: {CostBy: this.CostByOptions}
                });
                this.addBasesToRow(row, r, r.ServiceType.Name, period);
                row.modified.value = true;
                row.values.sort(function(a, b) {
                    return a.sort - b.sort;
                });
                period.table.rows[Object.keys(period.table.rows).length] = row;
            }
        },
        async getServices () {
            let res = await api.get("/api/service/", {
                SupplierId: this.record.SupplierId? this.record.SupplierId: this.record.id,
                Relations: true
            });
            if (res) this.services = res;
        },
        async changed (i, k, p) {
            this.period.table.rows[i].modified.value = true;
            await this.$nextTick();
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
            let res = this.audit(i, k, p);
            this.error = false;
            this.danger = false;
            if (res) {
                this.error = res.msg;
                if (res.danger) this.danger = true;
            }
        },
        async remove (i, fieldName, p) {
            this.period.table.rows[i].modified.value = true;
            this.period.table.rows[i].Closed.value = true;
            this.period.table = Object.assign([], this.period.table);
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        async removeAll () {
            for (let i in this.period.table.rows) {
                this.period.table.rows[i].modified.value = true;
                this.period.table.rows[i].Closed.value = true;
            }
            this.period.table = Object.assign([], this.period.table);
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        async removeEmpty () {
            for (let i in this.period.table.rows) {
                let fields = _.filter(this.period.table.rows[i].values, (v) => {
                    return (this.period.bases.indexOf(v.fieldName) > -1);
                })
                let found = _.find(fields, (r) => {
                    return r.value;
                })
                if (!found) {
                    this.period.table.rows[i].modified.value = true;
                    this.period.table.rows[i].Closed.value = true;
                }
            }
            this.period.table = Object.assign([], this.period.table);
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },

        removeBase (i, base) {
            this.period.table.header.splice(i, 1);
            for (let j in this.period.table.rows) {
                this.period.table.rows[j].values.splice(i, 1);
                this.period.table.rows[j].modified.value = true;
            }
            this.period.table = Object.assign([], this.period.table);
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        async addService (r) {
            let row = { values: [] };
            row.id = {
                fieldName: "id",
                value: null,
                editable: false,
                hidden: true
            };
            row.Closed = {
                fieldName: "Closed",
                value: null,
                editable: false,
                hidden: true
            };
            row.syncVersion = {
                fieldName: "syncVersion",
                value: null,
                editable: false,
                hidden: true
            };
            row.values.push({
                fieldName: "Name",
                value: r.name,
                editable: false,
                width: "300px",
                sort: -4,
                className: 'width-300'
            });
            row.values.push({
                fieldName: "ServiceType",
                value: r.type,
                editable: false,
                sort: -3
            });
            row.ServiceId = {
                fieldName: "ServiceId",
                value: r.id,
                editable: false,
                hidden: true
            };
            if (this.hasCategories) {
                row.values.push({
                    fieldName: "ServiceCategoryId",
                    value: null,
                    editable: true,
                    editor: "select",
                    fieldOptions: {
                        ServiceCategoryId: this.getCategories(r.id)
                    },
                    addBlank: true,
                    sort: -2
                });
            }
            row.values.push({
                fieldName: "CurrencyId",
                value: r.CurrencyId,
                editable: true,
                sort: -1,
                editor: "select",
                fieldOptions: { CurrencyId: this.currencyList }
            });
            row.values.push({
                fieldName: "CostBy",
                value: r.CostBy,
                editable: true,
                sort: -1.5,
                editor: "select",
                fieldOptions: {CostBy: this.CostByOptions}
            });
            this.addBasesToRow(row, r, r.type, this.period);
            row.values.sort(function(a, b) {
                return a.sort - b.sort;
            });
            let cnt = Object.keys(this.period.table.rows).length;
            this.period.table.rows[cnt] = row;
            this.period.table = Object.assign({}, this.period.table);
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
            $("#addService").modal("hide");
            this.newService = false;
        },
        addBasesToRow (row, r, servicetype, period) {
            for (let base of period.bases) {
                var value = null;
                if (r.Data) {
                    value = r.Data[base];
                }
                let canEdit = false;
                let costBy = r.CostBy? r.CostBy: r.ServiceType.CostBy;
                if ((costBy == "BASE_PERSON" && base != "ADT" && base != "CHD") || value) canEdit = true;
                if ((costBy == "BASE_SERVICE" && base != "ADT" && base != "CHD") || value) canEdit = true;
                if ((costBy == "SERVICE" && base == "1") || value) canEdit = true;
                if ((costBy == "PERSON" && (base == "ADT" || base == "CHD")) || value) canEdit = true;
                row.values.push({
                    fieldName: base,
                    value: value,
                    editable: canEdit,
                    editor: "number",
                    width: "40px",
                    isBase: true,
                    sort: this.getBaseSort(base)
                });
            }
            row.modified = { editable: false, hidden: true, value: false };
            row.hidden = { editable: false, hidden: true, value: false };
        },
        cancelNewBase () {
            this.newBase = false;
            this.editBase = false;
        },
        editBaseValue (new_key) {
            if (!new_key) return;
            let v = _.find(this.period.table.header, (c) => c.value == this.baseToEdit);
            if (v) v.value = new_key;
            let index = this.period.bases.indexOf(this.baseToEdit)
            if (index > -1) this.period.bases[index] = new_key;
            if (this.period.rows) {
                for (let row of this.period.rows) {
                    row.Data[new_key] = row.Data[this.baseToEdit];
                    delete row.Data[this.baseToEdit];
                }
            }
            for (let id in this.period.table.rows) {
                let row = this.period.table.rows[id];
                let v2 = _.find(row.values, (c) => c.fieldName == this.baseToEdit);
                if (v2) v2.fieldName = new_key;
                row.modified.value = true;
            }
            this.editBase = false;
            this.baseToEdit = null;
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        addBase () {
            let base;
            if (!this.extraPax) {
                let from_value = this.fromValue;
                let to_value = this.toValue;
                if (from_value == to_value || !to_value) {
                    base = from_value;
                } else {
                    base = from_value + ":" + to_value;
                }
            } else {
                base = 'Extra';
            }
            if (this.editBase) {
                this.editBaseValue(base);
                return;
            }

            for (let i in this.period.table.rows) {
                let row = this.period.table.rows[i];
                row.modified.value = true;
                let m = null;
                for (let key in row) {
                    if (
                        row[key].fieldName &&
                        key > this.period.header_cols - 1
                    ) {
                        m = key;
                    }
                }

                let r = _.find(this.services, (s) => s.id == row.ServiceId.value);
                if (!r) continue;
                let canEdit = false;
                let costBy = r.CostBy? r.CostBy: r.ServiceType.CostBy;
                if ((costBy == "BASE_PERSON" && base != "ADT" && base != "CHD")) canEdit = true;
                if ((costBy == "BASE_SERVICE" && base != "ADT" && base != "CHD")) canEdit = true;
                if ((costBy == "SERVICE" && base == "1")) canEdit = true;
                if ((costBy == "PERSON" && (base == "ADT" || base == "CHD"))) canEdit = true;
                let found = _.find(row.values, (r) => r.fieldName == base);
                if (found) continue;
                row.values.push({
                    fieldName: base,
                    value: null,
                    editable: canEdit,
                    editor: "number",
                    isBase: true,
                    width: "40px",
                    className: row.className,
                });
            }
            let found = _.find(this.period.table.header, (r) => r.value == base);
            if (!found) {
                this.period.table.header.push({
                    value: base,
                    sort: this.getBaseSort(base)
                });
                this.period.table = Object.assign({}, this.period.table);
                this.period.bases.push(base);
                this.newBase = false;
            }
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        getBaseSort (base) {
            let isRange = base.indexOf(":") > -1;
            if (isRange) {
                return parseFloat(base.split(":")[0]) + parseFloat(base.split(":")[1]) / 100;
            }
            return parseInt(base);
        },
        getCategories (ServiceId) {
            let res = [];
            let service = _.find(this.services, c => c.id == ServiceId);
            if (service) {
                let found = false;
                if (service.ServiceCategories.length > 0) {
                    for (let c of service.ServiceCategories) {
                        if (c.Closed) continue;
                        res.push({ label: c.Name, value: c.id });
                        found = true;
                    }
                }
                if (!found && this.record.ServiceCategories && this.record.ServiceCategories.length > 0) {
                    for (let c of this.record.ServiceCategories) {
                        if (c.Closed) continue;
                        res.push({ label: c.Name, value: c.id });
                    }
                }
            }
            return res;
        },
        async setRowsModified () {
            await this.$nextTick();
            for (let i in this.period.table.rows) {
                let row = this.period.table.rows[i];
                row.modified.value = true;
            }
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        async setBlackout () {
            this.setRowsModified();
        },
        async setClosed () {
            this.setRowsModified();
        }, 
        async setTourLeader () {
            this.setRowsModified();
        },
        async commentChange () {
            let value = this.period.Comment;
            let field = $("#Comment");
            field.tooltip('dispose');
            field[0].classList.remove('border-danger');
            var regex = new RegExp("^[a-zA-Z0-9 .-]+$");
            if (value && !regex.test(value)) {
               field.tooltip({title: tr('Special characters are not allowed'), placement: 'top'})
               field.tooltip('show');
               field[0].classList.add('border-danger');
               return false;
            }
            await this.setRowsModified();
        },
        close () {
            this.$emit('close');
            $('#service-cost-modal').modal('hide');
        },
        setIncrease () {
            this.increase = false;
            if (!this.increaseValue) return;
            for (let i in this.period.table.rows) {
                let row = this.period.table.rows[i];
                row.modified.value = true;
                for (let base of this.period.bases) {
                    let field = _.find(row.values, (r) => {
                        return r.fieldName == base;
                    })
                    if (field) {
                        if (field.value) {
                            let v = field.value;
                            if (typeof field.value === 'string') {
                                v = parseFloat(field.value.replace(/,/g, ''));
                            }
                            field.value = v * (1 + this.increaseValue / 100);
                        }
                    }
                }
            }
            this.period.table = Object.assign([], this.period.table);
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');

        },

        removeCategories () {
            for (let i in this.period.table.rows) {
                let row = this.period.table.rows[i];
                let field = _.find(row.values, (r) => {
                    return r.fieldName == 'ServiceCategoryId';
                });
                if (field) field.value = null;
                if (!row.modified) row.modified = { editable: false, hidden: true, value: false };
                row.modified.value = true;
            }
            let serviceIds = {};
            for (let i in this.period.table.rows) {
                let row = this.period.table.rows[i];
                if (!serviceIds[row.ServiceId.value]) {
                    serviceIds[row.ServiceId.value] = row.id.value;
                } else {
                    row.modified.value = true;
                    row.Closed.value = true;
                    let nrow = _.find(this.period.table.rows, (r) => {
                        let rowId = serviceIds[row.ServiceId.value];
                        return rowId == r.id.value;
                    })
                    if (nrow) {
                        let name = _.find(nrow.values, (r) => {
                            return r.fieldName == 'Name';
                        })
                        for (let base of this.period.bases) {
                            let field = _.find(nrow.values, (r) => {
                                return r.fieldName == base;
                            })
                            let field2 = _.find(row.values, (r) => {
                                return r.fieldName == base;
                            })
                            if (field && field2 && field2.value) {
                                field.value = field2.value;

                            }
                        }
                    }
                }
            }
            this.period.table = Object.assign({}, this.period.table);
            this.$emit('update:period', this.period);
            this.$emit('updateRecord');
        },
        audit (i, k, p) {
            let rows = this.record.ServiceCost;
            let row = rows[i];
            if (!row) return;
            let data = row.Data;
            if (!data) return;
            let price_found = false;
            if (data.ADT && row.CostBy == 'PERSON') return '';
            let values = []
            for (let key in data) {
                if (data[key]) {
                    let price_found = true;
                    if (!isNaN(key)) {
                        values.push({key: parseInt(key), value: parseFloat(data[key])});
                    } else {
                        if (key.includes(':')) {
                            let first_key = key.split(':')[0]
                            if (!isNaN(first_key)) {
                                values.push({key: parseFloat(first_key), value: parseFloat(data[key])});
                            }
                        }
                    }
                }
            }
            values.sort((a, b) => {
                if (a.key > b.key) return 1;
                if (a.key < b.key) return -1;
                return 0;
            });
            let increasing = 0;
            let decreasing = 0;
            for (let i=0; i<values.length; i++) {
                if (i > 0) {
                    let _this = values[i]
                    let last = values[i - 1]
                    if (_this.value > last.value) increasing += 1;
                    if (_this.value < last.value) decreasing += 1;
                }
            }
            let rowNr = parseInt(i) + 1;
            let total = increasing + decreasing;
            if (increasing/total > 0.6) {
                if (row.CostBy == 'BASE_PERSON' || row.CostBy == 'PERSON') {
                    return {msg: `Error in row ${rowNr}, price increasing in price Per Person`, danger: true};
                }
            } else if (decreasing/total > 0.6) {
                if (row.CostBy == 'SERVICE' || row.CostBy == 'BASE_SERVICE') {
                    return {msg: `Error in row ${rowNr}, price decreasing in price Per Service`, danger: true};
                }
            }
            if (increasing && !decreasing && row.CostBy == 'BASE_SERVICE') return '';
            if (decreasing && !increasing && row.CostBy == 'BASE_PERSON') return '';
            if (increasing && !decreasing && row.CostBy == 'SERVICE') return '';
            if (!increasing && !decreasing && row.CostBy == 'BASE_SERVICE') return '';
            if (!increasing && !decreasing && row.CostBy == 'BASE_PERSON') return '';
            if (decreasing && increasing) return {msg: `row ${rowNr}, price increasing and decreasing`};
            if (price_found) return {msg: `Error in row ${rowNr}`};
            return '';
        }
    }
};
</script>

