<template>
    <span>
        <svg viewBox="0 0 18 18" class="ql-custom-icon">
            <g fill="none" fill-rule="evenodd">
                <path class="ql-stroke" d="M8,9 L15,2 M11,1 L16,1 L16,6 M7,16 L2,16 L2,11"/>
                <rect class="ql-stroke" x="2" y="2" width="14" height="14" rx="2"/>
                <path class="ql-stroke" d="M5,5 L5,5 C7,5 7,7 5,7 L5,7"/>
            </g>
        </svg>
    </span>
</template>

<script>
export default {
    name: 'image-link'
}
</script>

<style>
.ql-snow .ql-image-link.ql-active > span > svg,
.ql-snow .ql-image-link > span > svg {
    width: 18px;
    height: 18px;
}

.ql-snow .ql-image-link .ql-stroke {
    stroke: currentColor;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.ql-snow .ql-image-link.ql-active .ql-stroke {
    stroke: #06c;
}
</style>