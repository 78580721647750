<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/bookinglist"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :fieldClasses="fieldClasses"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "booking-list-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            operManager: state => state.main.operManager,
            dmcManager: state => state.main.dmcManager,
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        if (this.user.Department && this.user.Department.Oper && !this.managerActions && !this.operManager && !this.dmcManager) {
            this.current.OperUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'OperUserId');
            field.disabled = true;
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        let bookingView = api.importMixinModule('booking', 'views');
        return {
            current: {},
            ready: false,
            fields: [
                {
                    name: "FromDate",
                    editor: "date",
                    label: "Date Inn From",
                },
                {
                    name: "ToDate",
                    editor: "date",
                    label: "Date Inn To",
                },
                {
                    name: "TransDateFrom",
                    editor: "date",
                    label: "Created From",
                },
                {
                    name: "TransDateTo",
                    editor: "date",
                    label: "Created To",
                },
                {name: 'PaxName', label: 'Passenger'},
                {name: 'SalesUserId', label: 'Seller', editor: 'select',
                    optionLabels: 'UserName', relation: 'user',
                    filters: (row) => {
                        if (row.Closed) return false;
                        if (row.Department && row.Department.Sales) return true;
                    }
                },
                {name: 'OperUserId', label: 'Oper User', editor: 'select',
                    optionLabels: 'UserName', relation: 'user',
                    filters: (row) => {
                        if (row.Closed) return false;
                        let department = _.find(api.tables.department, (r) => r.id == row.DepartmentId);
                        if (department) {
                            if (department.Oper) return true;
                        }
                    }
                },
                {
                    name: "Status",
                    label: "Status",
                    required: false,
                    editor: 'select',
                    options: bookingView.bookingStatusOptions,
                },
                {   name: 'AgencyId',
                    label: 'Agency',
                    editor: 'select',
                    optionLabels: 'Name',
                    relation: 'agency',
                },
            ],
            title: "Find Files",
            headers: {
                BookingId: "File Nr",
                StartDate: "Date Inn",
                PaxName: "Passenger",
                SalesUserName: "Seller",
                OperUserName: "Oper User",
            },
            templates: [
                {
                    name: "StartDate",
                    callback: (row) => {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {name: 'Status', callback: (row) => {
                        return tr(bookingView.bookingStatus[row.Status]);
                    }
                },
            ],
            columnClick: {
                BookingId: this.showBooking,
            },
            fieldClasses: {
                BookingId: (row) => {
                    return 'cursor-pointer-report';
                },
            },
        };
    },
    methods: {
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-booking', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        }
    }
};
</script>
