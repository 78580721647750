<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/register_activity"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :slotsFields="slotsFields"
            :updateReport="updateReport"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "register-activity",
    props: [],
    components: {
        ReportWindow,
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ready: false,
            fields: [
                {
                    name: 'FromDate',
                    editor: 'date',
                    label: 'From',
                    required: true
                },
                {
                    name: "FromTime",
                    editor: "time",
                    required: true,
                    label: "UTC Time",
                },
                {
                    name: 'ToDate',
                    editor: 'date',
                    label: 'To',
                    required: true
                },
                {
                    name: "ToTime",
                    editor: "time",
                    required: true,
                    label: "UTC Time",
                },
                {
                    name: "method",
                    editor: "select",
                    label: "Action",
                    addBlank: true,
                    options: [
                        {value: 'GET', label: 'Read'},
                        {value: 'POST', label: `${tr('Create')}/${tr('Update')}`},
                        //{value: 'PUT', label: 'Update'},
                        {value: 'DELETE', label: 'Delete'}
                    ],
                },
                {   
                    name: 'UserId', 
                    label: 'User', 
                    editor: 'select', 
                    optionLabels: 'UserName', 
                    relation: 'user',
                    addBlank: true,
                },
                {
                    name: "grep",
                    editor: "text",
                    label: "grep",
                },
                {
                    name: 'Translate',
                    editor: 'checkbox',
                    label: 'Translate',
                    defValue: true,
                },
                {
                    name: 'KeepAlive',
                    editor: 'checkbox',
                    label: `${tr('Include')} Keep Alive`,
                    defValue: false,
                }
            ],
            title: "Register Activity",
            headers: {
                UserName: "User",
                Date: "Date",
                Time: "Time",
                Method: "Action",
            },
            templates: [
                {name: 'RegisterDate', callback: (row) => {
                        if (!row.RegisterDate) return '';
                        return moment.utc(row.RegisterDate).local().format("DD MMM YYYY HH:mm:ss");
                    }
                },
                {
                    name: 'Path',
                    callback: (row) => {
                        if (!this.current.Translate) return row.Path;
                        let number = this.getNumber(row);
                        if (number) {
                            if (number.id == 0) {
                                return tr('New Record') + ': ' + tr(number.table);
                            } 
                            return this.tr(number.table) + ' ' + number.id;
                        }
                        return row.Path;
                    }
                },
                {
                    name: 'Method',
                    callback: (row) => {
                        if (!this.current.Translate) return row.Method;
                        let number = this.getNumber(row);
                        let isNew = false;
                        if (number) {
                            if (number.id && number.id == 0) {
                                isNew = true;
                            }
                        }
                        return {
                            'GET': tr('Read'),
                            'POST': isNew ? tr('Create') : tr('Update'),
                            'PUT': tr('Update'),
                            'DELETE': tr('Delete')
                        }[row.Method]
                    }
                }
            ],
            columnClick: {},
            slotsFields: [],
        };
    },
    methods: {
        getNumber (row) {
            let r = row.Path.replace(/^\/api\//, '')
            let words = r.split('/');
            if (words.length == 2) {
                let isNumber = !Number.isNaN(parseInt(words[1]));
                if (isNumber) {
                    return {table: words[0], id: words[1]};
                }
            } else if (words.length == 3) {
                if (words[1] == 'reports') {
                    return {table: 'Report', id: tr(words[2])};
                }
                if (words[0] == 'bookingnote' && words[1] == 'quant') {
                    return {table: `${tr('Notes')} ${tr('in')} ${tr('Quote')}/${tr('File')}`, id: words[2]};
                }
            }
            return null;
        },
        tr (table) {
            if (table == 'booking') return `${tr('Quote')}/${tr('File')}`;
            return tr(table);
        }
    }

};
</script>
