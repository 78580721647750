<template>
  <div>
    <gallery :images="imagesURLs" :index="index" @close="index = null" v-if="imagesURLs.length > 0"></gallery>
    <div class="row image-list">
        <photo-croppa
            :key="imageIndex"
            :resize="true"
            divClass="croppa-image"
            imgClass="image gallery-row-image"
            v-for="(image, imageIndex) in imagesList"
            :imageIndex="imageIndex"
            :gallery="true"
            :url="image.src"
            :crop="crop"
            @set="setIndex"
            >
        </photo-croppa>
    </div>
  </div>
</template>

<script>
import VueGallerySlideShow from 'vue-gallery-slideshow';

export default {
    name: 'photo-gallery',
    props:  ['images', 'crop'],
    data () {
        return {
            index: null
        };
    },
    components: {
        'gallery': VueGallerySlideShow,
    },
    methods: {
        setIndex (i) {
            this.index = i;
        }
    },
    computed: {
        imagesList () {
          if (!this.images) return [];
          return this.images.filter(image => image.src);
        },
        imagesURLs () {
            if (!this.images) return [];
            return this.images.map(image => image.src).filter(src => src);
        }
    }
  }
</script>

<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
  .image-list {
    margin-left: 0px;
  }
</style>
