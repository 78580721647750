import '@/base/main.app';
//require('@/load_app');
//import LogRocket from 'logrocket';
//LogRocket.init('8bvjbz/nubbo');

if (!api.DOMAINS || api.DOMAINS.indexOf(window.top.location.hostname)>-1){
    var int = setInterval(() => {
        if (api && api._user) {
            require('@/load_app');
            clearInterval(int);
        }
    }, 100);
}

