<template>
    <div class="form-row">
        <div class="form-group col-md-12 mb-0">
            <div class="card-header section-title">
                <label>{{tr('Customer Information')}}</label>
            </div>
            <div class="form-row form-material" v-if="recordStore.tableSpec">
                <input-field
                    class="col px-3"
                    :field="recordStore.fieldsObject.PaxName"
                    :defValue.sync="recordStore.PaxName"
                    :invalid="recordStore.invalid.PaxName"
                    :readonly="!canEdit"
                    :maxLength="recordStore.fieldsObject.PaxName.length"
                ></input-field>
                <input-field
                    class="col px-3"
                    :field="recordStore.fieldsObject.PaxEmail"
                    :defValue.sync="recordStore.PaxEmail"
                    :readonly="!canEditPaxEmail"
                    :invalid="recordStore.invalid.PaxEmail"
                    :maxLength="recordStore.fieldsObject.PaxEmail.length"
                ></input-field>
                <input-field
                    class="col px-3"
                    :field="recordStore.fieldsObject.PaxPhone"
                    :defValue.sync="recordStore.PaxPhone"
                    :readonly="!canEditPaxPhone"
                    :invalid="recordStore.invalid.PaxPhone"
                    :maxLength="recordStore.fieldsObject.PaxPhone.length"
                ></input-field>
                <div class="col px-3">
                    <select-input fieldName="PaxCountryType"
                        :field="recordStore.fieldsObject.PaxCountryType"
                        :disabled="!canEdit"
                        label="Passenger Type"
                        :currentValue.sync="recordStore.PaxCountryType" :required="true"
                    ></select-input>
                </div>
                <input-field
                    class="col px-3"
                    :field="recordStore.fieldsObject.CustomerTypeId"
                    :defValue.sync="recordStore.CustomerTypeId"
                    :readonly="!canEdit"
                    :invalid="recordStore.invalid.CustomerTypeId"
                ></input-field>
                <div class="px-3 col-agency">
                    <input-field
                        v-if="recordStore.CustomerType &&  recordStore.CustomerType.Agency"
                        fieldName="AgencyId"
                        :field="recordStore.fieldsObject.AgencyId"
                        label="Agency"
                        :defValue.sync="recordStore.AgencyId"
                        :invalid="recordStore.invalid.AgencyId"
                        :addBlank="true"
                        :disabled="!canEdit"
                        >
                    </input-field>
                </div>
                <component
                  v-for="extra of extraComp"
                  :is="extra.component"
                  :ref="extra.ref"
                  @change="extra.change"
                ></component>
                <slot name="extras"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'booking-cover-pax',
    data () {
        return {
            extraComp: []
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            managerActions: state => state.main.managerActions,
        }),
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        },
        canEditPaxEmail () {
            return this.canEdit;
        },
        canEditPaxPhone () {
            return this.canEdit;
        }
    },
    methods:{
    }
}
</script>

