//import '@/base/main.app';
import '@/tools/vue-tools';
import '@/tools/front-tools';
import '@/tools/notifications';

import Vue from 'vue';
import App from '@/App';
import 'jquery-ui-dist/jquery-ui';
import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap-datepicker';
import 'bootstrap-3-typeahead';
import '@/css/bootstrap.min.css';
import '@/css/bootstrap-grid.min.css';
import '@/css/bootstrap-datepicker3.min.css';
import '@/css/dragula.min.css';
import '@/css/nunito.css';
//import html2canvas from 'html2canvas';
//window.html2canvas = html2canvas;

import $ from 'jquery';
import jQuery from 'jquery';
window.$ = $;
window.jQuery = $;
import * as _ from 'lodash';
window._ = _;
var moment = require('moment');
window.moment = moment;

import store from '@/store/store';
import router from '@/router/index';
import '@/tools/icons';

import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
Vue.use(Croppa);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);
import AsyncComputed from 'vue-async-computed';
Vue.use(AsyncComputed)
Vue.filter('toLocalDateTime', tools.getLocalDateTime);
Vue.filter('toLocalDate', tools.getLocalDate);
Vue.filter('toDate', tools.getDate);
Vue.filter('toDateTime', tools.getDateTime);
Vue.filter('toHourMinute', tools.getHourMinute);
Vue.filter('formatNumber', tools.formatNumber);

const selectInput = importVueComp('components/tools', 'SelectInput');
Vue.component('select-input', selectInput);
const datePicker = importVueComp('components/tools', 'DatePicker');
Vue.component('date-picker', datePicker);
const dateTime = importVueComp('components/tools', 'DateTime');
Vue.component('m-date-time', dateTime);
const numberInput = importVueComp('components/tools', 'NumberInput');
Vue.component('number-input', numberInput);
const vueSelectInput = importVueComp('components/tools', 'VueSelect');
Vue.component('vue-select-input', vueSelectInput);
const photoDiv = importVueComp('components/tools', 'Photo');
Vue.component('photo-div', photoDiv);
const photoSmall = importVueComp('components/tools', 'PhotoSmall');
Vue.component('photo-small', photoSmall);
const textAreaStyle = importVueComp('components/tools', 'TextAreaStyle');
Vue.component('text-area-style', textAreaStyle);
const attach = importVueComp('components/tools', 'Attach');
Vue.component('attach', attach);
const iFrame = importVueComp('components/tools', 'IFrame');
Vue.component('i-frame', iFrame);
const navBar = importVueComp('components/tools', 'NavBar');
Vue.component('nav-bar', navBar);
const sideBar = importVueComp('components/tools', 'SideBar');
Vue.component('side-bar', sideBar);
const textRowsStyle = importVueComp('components/tools', 'TextRowsStyle');
Vue.component('text-rows-style', textRowsStyle);
const photoPicker = importVueComp('components/tools', 'PhotoPicker');
Vue.component('photo-picker', photoPicker);
const selectValue = importVueComp('components/tools', 'SelectValue');
Vue.component('select-value', selectValue);
const yesNot = importVueComp('components/tools', 'YesNot');
Vue.component('yes-not', yesNot);
const photoCroppa = importVueComp('components/tools', 'PhotoCroppa');
Vue.component('photo-croppa', photoCroppa);
const blockUI = importVueComp('components/tools', 'BlockUI');
Vue.component('block-ui', blockUI);
const asyncImg = importVueComp('components/tools', 'AsyncImg');
Vue.component('async-img', asyncImg);
const breadcrumb = importVueComp('components/tools', 'Breadcrumb');
Vue.component('breadcrumb', breadcrumb);
const inputField = importVueComp('components/tools', 'InputField');
Vue.component('input-field', inputField);
const searchBox = importVueComp('components/tools', 'SearchBox');
Vue.component('search-box', searchBox);
const rowInputField = importVueComp('components/tools', 'RowInputField');
Vue.component('row-input-field', rowInputField);
const alertPopup = importVueComp('components/tools', 'AlertPopup');
Vue.component('alert-popup', alertPopup);
const recordWindowModal = importVueComp('components/tools', 'RecordWindowModal');
Vue.component('record-window-modal', recordWindowModal);
const inputValue = importVueComp('components/tools', 'InputValue');
Vue.component('input-value', inputValue);
const iconSVG = importVueComp('components/svg', 'Icon');
Vue.component('icon-svg', iconSVG);
const contextMenu = importVueComp('components/tools', 'ContextMenu');
Vue.component('context-menu', contextMenu);
import number from '@coders-tm/vue-number-format'
Vue.use(number, { precision: 2 })
const jsonEditor = importVueComp('components/tools', 'JsonEditor');
Vue.component('json-editor', jsonEditor);
const colorPicker = importVueComp('components/tools', 'ColorPicker');
Vue.component('color-picker', colorPicker);
const imageSelect = importVueComp('components/tools', 'ImageSelect');
Vue.component('image-select', imageSelect);
const inApp = importVueComp('components/tools', 'InApp');
Vue.component('in-app', inApp);

import Quill from '@/quill/quill.min.js';
window.Quill = Quill;

let BlockEmbed = Quill.import('blots/block/embed');
class TableBlot extends BlockEmbed {
    static blotName = 'table';
    static tagName = 'table';
    static className = 'table';
    constructor(node) {
        super(node);
    }
    length() {
        return 1;
    }
}
Quill.register(TableBlot, true);


import Rollbar from 'rollbar';


if (process.env.NODE_ENV=='production'){

    // Set the Rollbar instance in the Vue prototype
    // before creating the first Vue instance.
    // This ensures it is available in the same way for every
    // instance in your app.
    Vue.prototype.$rollbar = new Rollbar({
      accessToken: '0510db6eba45423a95c8db7dff692354',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        // Track your events to a specific version of code for better visibility into version health
        code_version: '1.0.0',
        // Add custom data to your events by adding custom key/value pairs like the one below
        custom_data: 'foo'
      }
    });

    // If you have already set up a global error handler,
    // just add `vm.$rollbar.error(err)` to the top of it.
    // If not, this simple example will preserve the app’s existing
    // behavior while also reporting uncaught errors to Rollbar.
    Vue.config.errorHandler = (err, vm, info) => {
      vm.$rollbar.error(err);
      throw err; // rethrow
    };
}



import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.API_GOOGLE_MAP,
    libraries: "places" // necessary for places input
  }
});



Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV!='production';
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')


if (process.env.NODE_ENV == 'production') {
    console.stdlog = console.log.bind(console);
    console.logs = [];
    console.log = function(){
        console.logs.push(Array.from(arguments));
        console.stdlog.apply(console, arguments);
    }
}

localStorage.setItem('reportFilters', '');
