<template>
    <div class="ql-main-container">
        <div :id="'tstyle-' + _uid" @click="setQuillStatus">
            <component is="svg-select-all" v-show="false" :ref="'svg-all-' + _uid"></component>
            <component is="paste-format" v-show="false" :ref="'paste-format-' + _uid"></component>
            <component is="image-link" v-show="false" :ref="'image-link-' + _uid"></component>
            <component v-for="c of extraButtons" :is="c.comp" :ref="c.name + '-' + _uid" v-show="false"></component>
        </div>
    </div>
</template>

<script>
import '@/quill/quill.snow.css';
import SelectAll from '@/components/svg/SelectAll';
import PasteFormat from '@/components/svg/PasteFormat';
import ImageLink from '@/components/svg/ImageLink';
import { mapState } from 'vuex';
export default {
    name: 'text-area-style',
    props: ['text', 'size', 'tabId', 'pasteText', 'id', 'maxHeight', 'disabled', 'showPin', 'extras'],
    components: {
        'svg-select-all': SelectAll,
        'paste-format': PasteFormat,
        'image-link': ImageLink,
    },
    data () {
        return {
            value: null,
            init: false,
            defSize: '14px',
            counter: 0,
            interval: null,
            ready: false,
            pasteFormat: false,
            skipUpdate: false,
        }
    },
    computed: {
        ...mapState({
            quillFocus: state => state.quill.quillFocus,
            quillStatus: state => state.quill.quillStatus,
        }),
          extraButtons () {
            return this.extras? this.extras: [];
        }
    },
    watch: {
        pasteText () {
            if (this.pasteText) {
                this.value = _.cloneDeep(this.pasteText);
                this.setText(this.value);
            }
        },
        counter () {
            if (this.counter>0) this.init = true;
            if (this.counter==0 && this.init) {
                this.emitUpdate(true)
            }
        },
        quillStatus () {
            if (!this.quillStatus) {
                this.emitUpdate(true);
            }
        }
    },
    async mounted (){
        let text = this.text;
        if (text) {
            text = this.convertOldLists(text);
        }
        this.value = _.cloneDeep(text);
        let self = this;
        setTimeout(() => {self.ready = true}, 100);
        if (this.size) this.defSize = this.size;

        let Size = Quill.import('attributors/style/size');
        Size.whitelist = ['12px', '14px', '16px', '18px'];
        Quill.register(Size, true);
        /*let BlockEmbed = Quill.import('blots/block/embed');
        class TableBlot extends BlockEmbed {
            static blotName = 'table';
            static tagName = 'table';
            static className = 'table';
            constructor(node) {
                super(node);
                //this.domNode.setAttribute('contenteditable', 'false');
            }
            length() {
                return 1;
            }
        }
        Quill.register(TableBlot);*/

        const COLORS = [
            "#000000", "#e60000", "#ff9900", "#ffff00", "#008A00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#2F5496", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"
          ];

        let toolbarOptions = {
            container: [
              { 'size':  ['12px', '14px', '16px', '18px'] },
              'bold',
              'italic',
              'underline',
              'clean',
              'select-all',
              'paste-format',
              { 'list': 'ordered'}, { 'list': 'bullet' }, {'color': COLORS}, {'background': COLORS},
              { 'align': ['', 'center', 'right', 'justify'] },
              'link',
              'image',
              'video',
              'image-link',
              //'emoji'
            ],
            //handlers: {'emoji': () => {}}
            handlers: {
                'select-all': () => { quill.setSelection(0, quill.getLength()) },
                'paste-format': () => { self.pasteFormat = !self.pasteFormat; },
                'image-link': () => {
                    const url = prompt('Enter the image URL:');
                    if (url) {
                        const range = quill.getSelection(true);
                        quill.insertEmbed(range.index, 'image', url);
                    }
                }
            }
        };
        if (this.extras) {
          for (let c of this.extras) {
             toolbarOptions.container.push(c.name)
             toolbarOptions.handlers[c.name] = () => {c.callback(self)};
          }
        }

        let quillId = '#tstyle-' + this._uid;
        let quill = new Quill(quillId, {
            'modules': {
              'toolbar': toolbarOptions,
            },
            theme: 'snow',
            readOnly: self.disabled,
        });

        this.setText(this.value);


        quill.on('text-change', (delta, oldDelta, source) => {
            if (source == 'user') {
                if (!self.interval && self.ready) {
                    self.setInterval();
                }
            }
        });

        quill.root.addEventListener('blur', () => {
            this.emitUpdate(true)
        });

        quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
          if (!self.pasteFormat) {
              delta.ops = delta.ops.map(op => {
                return {
                  insert: op.insert
                }
              })
              setTimeout(() => {
                  self.emitUpdate(true);
              }, 10)
          }
          return delta
        })

        if (this.tabId) {
            let customButtons = $('#' + this.tabId + ' .ql-clean');
            if (customButtons && customButtons.length>0) {
                for (let customButton of customButtons) {
                    customButton.addEventListener('click', () => {
                        let text = quill.getText(0, quill.getLength());
                        quill.setText(text);
                        quill.formatText(0, quill.getLength() );
                        self.$emit("update:text", text);
                    });
                }
            }
        }

        await this.$nextTick();

        let qdiv = $('#tstyle-' + self._uid).parent()[0];
        if (this.maxHeight) {
            $('#tstyle-' + self._uid).height(this.maxHeight);
        }
        let toolbar = $(qdiv).find('.ql-toolbar')[0];

        let child = self.$refs['svg-all-' + self._uid];
        if (child) {
            let html = child.$el.innerHTML;
            let cleanAll = $(toolbar).find('.ql-select-all');
            if (cleanAll && cleanAll.length>0) {
                cleanAll.append(html)
                /*cleanAll[0].addEventListener('click', () => {
                    quill.setSelection(0, quill.getLength());
                });*/
            }
        }

        let child2 = self.$refs['paste-format-' + self._uid];
        if (child2) {
            let html2 = child2.$el.innerHTML;
            let pasteFormat = $(toolbar).find('.ql-paste-format');
            if (pasteFormat && pasteFormat.length>0) {
                pasteFormat.append(html2)
                /*pasteFormat[0].addEventListener('click', async () => {
                    self.pasteFormat = !self.pasteFormat;
                });*/
            }
        }

        if (this.extras) {
          for (let c of this.extras) {
            let child4 = self.$refs[c.name + '-' + self._uid];
            if (child4 && child4[0]) {
                let html4 = child4[0].$el.innerHTML;
                let button = $(toolbar).find('.ql-' + c.name);
                if (button && button.length>0) {
                    button.append(html4)
                    /*button[0].addEventListener('click', async () => {
                        c.callback(self)
                    });*/
                }
            }

          }
        }

        let imageLink = self.$refs['image-link-' + self._uid];
        if (imageLink) {
            let htmlImageLink = imageLink.$el.innerHTML;
            let imageLinkButton = $(toolbar).find('.ql-image-link');
            if (imageLinkButton && imageLinkButton.length>0) {
                imageLinkButton.append(htmlImageLink)
            }
        }

    },
    methods: {
        convertOldLists (html) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            const ulElements = tempDiv.querySelectorAll('ul');
            if (ulElements && ulElements.length>0) {
                ulElements.forEach(ul => {
                    const ol = document.createElement('ol');
                    ol.setAttribute('data-list', 'bullet');
                    Array.from(ul.children).forEach(li => {
                        const newLi = document.createElement('li');
                        newLi.setAttribute('data-list', 'bullet');
                        newLi.innerHTML = li.innerHTML;
                        ol.appendChild(newLi);
                    });
                    ul.replaceWith(ol);
                });
                return tempDiv.innerHTML;
            }
            return html;
        },
        getText () {
            let self = this;
            return $(self.$el).find('.ql-editor')[0].innerHTML;
        },
        setValue (value, skipUpdate) {
            this.skipUpdate = true;
            this.value = value;
            this.setText(value);
        },
        setText (value) {
            $(this.$el).find('.ql-editor')[0].innerHTML = value;
        },
        setInterval: function() {
            let self = this;
            this.counter = 1500;
            this.interval = setInterval(() => {
                if (self.counter>0) {
                    self.counter += -500;
                } else {
                    clearInterval(self.interval)
                    self.interval = null;
                }
            }, 500);
        },
        setQuillStatus () {
            if (!this.quillFocus) {
                this.$store.commit('setQuillFocus', 'tstyle-' + this._uid);
            }
            this.$store.commit('setQuillStatus', true);
        },
        emitUpdate (change) {
            if (!this.skipUpdate) {
                this.$emit("update:text", this.getText());
                if (change) this.$emit("change");
            }
            this.skipUpdate = false;

        },
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
}
</script>

