<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" 
       width="100pt" height="76pt" 
       viewBox="0 0 100 76" 
       preserveAspectRatio="xMidYMid meet"
       fill="currentColor">
    <!-- Dollar Sign -->
    <path d="M50 12v52M60 24c0-6-6-10-15-10s-15 4-15 10c0 14 30 6 30 20 0 6-6 10-15 10s-15-4-15-10" 
          stroke="currentColor" 
          stroke-width="8" 
          fill="none"/>
    <!-- Diagonal Slash -->
    <line x1="20" y1="64" x2="80" y2="12" stroke="currentColor" stroke-width="5"/>
  </svg>
</template>

<script>
export default {
  name: 'slashed-dollar'
}
</script>