<template>
    <div class="modal fade bd-example-modal-lg" id="photoPicker" tabindex="-1" role="dialog" aria-labelledby="photoPickerLabel"
        data-backdrop="static" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" v-if="imageApi">
          <div class="modal-header">
            <div class="row col-12">
                <div class="col-12 d-flex align-items-center">
                    <h4 class="modal-title" id="photoPickerLabel">{{tr('Select Picture')}}</h4>
                    <button type="button" class="close" @click="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="col-12 mt-2">
                    <p class="modal-title col-md-6" v-if="imageApi.path && !listMode">{{getRouteName}}</p>
                </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="col-12 row" :class="matClass">
                <search-box :material="true" class="col-11" v-model="search"></search-box>
                <i class="col-1 float-right cursor-pointer" @click="listMode = !listMode"
                    v-if="search"
                    :class="{'icon-active': listMode}"
                    data-toggle="tooltip" data-placement="top" :title="tr(listMode? 'View List': 'View Pictures')">
                    <font-awesome-icon icon="list"/>
                </i>
            </div>
            <div v-if="!processing">
                <div class="row" v-if="!search || !listMode">
                    <div class="col-2 row" v-for="f of getFolders" v-if="getFolders">
                        <i class="folder-preview col-12" @click="setFolder(f)" @contextmenu.prevent="handleClick2($event, {folder: f})">
                          <font-awesome-icon icon="folder-open"/>
                        </i>
                        <p class="col-12 text-center">{{f}}</p>
                    </div>

                    <div class="col-2" v-for="img of imageList" v-if="img">
                        <div data-file-uploader="preview" class="photo-preview row">
                            <a class="col-12 cursor-pointer" @click.shift="setSrc2(img)" @click="setSrc(img)"
                            @contextmenu.prevent="handleClick($event, { id: img.id })">
                              <img :src="img.url" class="img-fluid rounded" alt="Responsive image" :class="{'image-selected': imgSelected(img)}">
                            </a>
                            <p class="col-12 text-center">{{img.name}}</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="col-12 row mb-1" v-for="r of imageList">
                        <div class="col-2 px-0">
                            <a class="cursor-pointer" @click="setSrc(r.img)">
                              <img :src="r.img.url" class="img-fluid rounded" alt="Responsive image"  width="100">
                            </a>
                        </div>
                        <div class="col-10 px-0 d-flex align-items-center">
                            <label>
                                <span v-for="v of highLight(r.key)" :class="v.class">{{v.t}}</span>
                                <span v-for="v of highLight(r.img.name)" :class="v.class">{{v.t}}</span>
                            </label>
                        </div>

                    </div>
                </div>
            </div>
            <div v-else>
                <img class="loading-gif" src="@/img/loading.gif">
            </div>

          </div>
          <div class="modal-footer form-material ">
            <button type="button" class="btn btn-primary btn-sm" @click="multiSetSrc" v-if="selectList.length>0">
                {{tr('Add')}}
            </button>
            <button type="button" class="btn btn-primary btn-sm" @click="back" v-if="imageApi.path && !showInputValue">
                {{tr('Back')}}
            </button>
            <button type="button" class="btn btn-primary btn-sm" @click="showInputValue = true" v-if="canCreateFolder && !showInputValue">
                {{tr('New Folder')}}
            </button>
            <div class="form-group form-default" v-if="showInputValue">
                <input type="text" v-model="folderName" class="form-control" :class="{'fill': folderName}" >
                    <span class="form-bar"></span>
                </input>
                <label class="float-label">{{tr('Folder Name')}}</label>
            </div>
            <button type="button" class="btn btn-primary btn-sm" @click="addFolder" v-if="showInputValue">
                {{tr('OK')}}
            </button>
            <button type="button" class="btn btn-primary btn-sm" @click="showInputValue = false" v-if="showInputValue">
                {{tr('Cancel')}}
            </button>
            <label class="btn btn-outline-secondary image-label btn-sm m-0" v-if="canUploadPhoto && !listMode">
                {{tr('Upload')}}
                <input type="file" style="display: none;"  multiple
                accept=".jpg,.jpeg,.gif,.png" @change="uploadImage($event)">
            </label>
          </div>
        </div>
      </div>
      <!--input-value
        v-if="showInputValue"
        @save="addFolder"
        @close="closeInputValue"
        text="Input Folder Name"
      ></input-value-->
      <input-value
        v-if="showRename"
        @save="renameImage"
        @close="closeShowRename"
        :options="options"
        text="Input Image Name"
      ></input-value>
      <image-names
        v-if="imageName"
        @save="setImageName"
        @close="closeSetImage"
        :names.sync="names"
      ></image-names>
      <context-menu
          :options="menuOptions"
          ref="menu"
          @clicked="menuClicked"
      ></context-menu>
      <context-menu
          :options="menu2Options"
          ref="menu2"
          @clicked="menuClicked2"
      ></context-menu>


    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
const imageNames = importVueComp('components/tools', 'ImageNames');
import VueContext from 'vue-context';
import { mapState } from 'vuex';
export default {
    name: 'photo-picker',
    mixins: [escape],
    props: ['openLink', 'set', 'show', 'matClass'],
    components: {
      'vue-context': VueContext,
      'image-names': imageNames
    },
    data () {
        return {
            imageSrc: null,
            showInputValue: false,
            showRename: false,
            imageName: false,
            options: null,
            search: null,
            toMove: null,
            isFolder: false,
            processing: false,
            imageApi: null,
            names: [],
            selectList: [],
            shift: false,
            promises: [],
            listMode: false,
            folderName: null,

        }
    },
    async mounted () {
        $('#photoPicker').modal({backdrop: 'static', keyboard: false}, 'show');
        this.processing = true;
        this.imageApi = await new imageApi();
        this.processing = false;
    },
    methods: {
        handleClick (event , obj) {
            this.$refs.menu.showMenu(event, obj)
        },
        handleClick2 (event , obj) {
            this.$refs.menu2.showMenu(event, obj)
        },
        menuClicked (option, {text, id, folder}) {
            if (option.name == 'rename') {
                this.rename(text, id);
            }
            if (option.name == 'remove') {
                this.remove(text, id);
            }
            if (option.name == 'cut') {
                if (this.selectList.length>0) {
                    let ids = _.map(this.selectList, (r) => r.id);
                    this.cut(text, ids);
                    return;
                }
                this.cut(text, [id]);
            }
            if (option.name == 'copyUrl') {
                this.copyUrl(text, id);
            }
        },
        menuClicked2 (option, {text, folder}) {
            if (option.name == 'removeFolder') {
                this.removeFolder(text, folder);
            }
            if (option.name == 'paste') {
                this.move(text, folder);
            }
            if (option.name == 'cut') {
                this.cutFolder(text, folder);
            }
        },
        close () {
            this.$emit('update:show', false);
            $('#photoPicker').modal('hide');
            setTimeout(() => {
                if ($('.modal:visible').length) {
                    $('body').addClass('modal-open');
                }
            }, 500);
        },
        setSrc2 (img) {
            this.shift = true;
            //let index = this.selectList.indexOf(img.id);
            let index = _.findIndex(this.selectList, (r) => r.id == img.id);
            if (index==-1) {
                this.selectList.push(img);
            } else {
                this.selectList.splice(index, 1);
            }
        },
        imgSelected (img) {
            let index = _.findIndex(this.selectList, (r) => r.id == img.id);
            return index > -1;
        },
        setSrc (img) {
            if (this.shift) {
                this.shift = false;
                return;
            }
            if (!this.set) return;
            if (this.selectList.length>0) {
                return;
            }
            if (this.openLink) {
                window.open(img.url, '_blank');
                return;
            }
            this.$emit('setSrc', [img])
            //$('#photoPicker').modal('hide');
            //this.$emit('update:show', false);
            this.close();
        },
        multiSetSrc () {
            this.$emit('setSrc', this.selectList)
            this.close();
        },
        uploadImage (event) {
            this.options = {event: event};
            this.names = [];
            let input = this.options.event.target;
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    let src = URL.createObjectURL(input.files[i])
                    this.names.push({value: null, src:src});
                }
            }
            this.imageName = true;
            //document.removeEventListener('keydown', this.eventListener);
        },
        async setImageName () {
            let input = this.options.event.target;
            if (input.files) {
                this.processing = true;
                this.promises = [];
                for (let i = 0; i < input.files.length; i++) {
                    let value = this.names[i].value;
                    let p = await this.imageApi.setImageName(input.files[i], this.names[i].value, this.set);
                    this.promises.push(p)
                }
                this.processing = false;
                if (this.set) {
                    let images = []
                    for (let res of this.promises) {
                        if (res) {
                            images.push(res);
                        }
                    }
                    this.$emit('setSrc', images);
                    this.close();
                    //$('#photoPicker').modal('hide');
                    //this.$emit('update:show', false);
                }
            }
        },
        closeSetImage () {
            this.imageName = false;
        },
        closeInputValue () {
            this.showInputValue = false;
        },
        closeShowRename () {
            this.showRename = false;
        },
        createFolder () {
            this.showInputValue = true;
        },
        async addFolder () {
            this.closeInputValue();
            if (this.folderName) {
                this.processing = true;
                await this.imageApi.addFolder(this.folderName);
                this.folderName = null;
                this.processing = false;
            }
        },
        setFolder (f) {
            this.imageApi.setFolder(f);
            this.search = null;
        },
        back () {
            this.imageApi.back();
        },
        rename (text, id) {
            this.options = {id: id}
            this.showRename = true;
            document.removeEventListener('keydown',this.eventListener);
        },
        copyUrl (text, id) {
            let img = this.imageList.find((r) => r.id == id);
            if (img) {
                navigator.clipboard.writeText(img.url);
            }
        },
        async renameImage (value, options) {
            this.closeShowRename();
            this.processing = true;
            await this.imageApi.renameImage(options.id, value);
            this.processing = false;
        },
        async remove (text, id) {
            this.processing = true;
            await this.imageApi.remove(id);
            this.processing = false;
        },
        cut (text, ids) {
            this.toMove = ids;
            this.isFolder = false;
        },
        cutFolder (text, url) {
            this.toMove = this.imageApi.path + url + '/';
            this.isFolder = true;
        },
        async move (text, folder) {
            this.processing = true;
            let res = await this.imageApi.move(text, folder, this.isFolder, this.toMove);
            if (res) {
                this.selectList = [];
                if (!this.isFolder) {
                    for (let img of res) {
                        this.updateFilePath(img);
                    }
                } else {
                    this.updateFolderPath();
                }
            }
            this.processing = false;
        },
        async removeFolder (text, folder) {
            this.processing = true;
            if (this.imageApi.files[this.imageApi.path + folder + '/'] && this.imageApi.files[this.imageApi.path + folder + '/'].length > 0) {
                alert(tr('Folder must be empty'));
                this.processing = false;
                return;
            }
            let res = await this.imageApi.removeFolder(this.imageApi.path + folder);
            if (res) {
                await this.imageApi.updateFolderPath();
                if (!this.isFolder) {
                    this.updateFilePath(res);
                } else {
                    this.updateFolderPath();
                }
            }
            this.processing = false;
        },
        async updateFolderPath () {
            await this.imageApi.updateFolderPath(this.toMove);
            this.toMove = null;
            this.isFolder = false;
            this.processing = false;
        },
        updateFilePath (img) {
            if (this.imageApi.updateFilePath(img.id, img)) {
                this.toMove = null;
            }
        },
        highLight (text) {
            if (!text) return [];
            let res = [{t: text}];
            let values = this.search.split(' ');
            for (let value of values) {
                let re = new RegExp(tools.normalize(value), 'i');
                for (let i in res) {
                    let r = res[i];
                    let match = re.exec(tools.normalize(r.t));
                    if (match) {
                        res.splice(i, 1);
                        let start = match.index;
                        let end = start + value.length;
                        res.push({t: r.t.substring(0, start)});
                        res.push({t: r.t.substring(start, end), class: 'hilite'});
                        res.push({t: r.t.substring(end, r.t.length)});
                    }
                }

            }
            return res;
        },
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
        menuOptions () {
            let res = [
                {label: tr('Remove'), name: 'remove', confirm: true, hide: this.selectList.length > 0 },
                {label: tr('Rename'), name: 'rename', hide: this.selectList.length > 0 },
                {label: tr('Cut'), name: 'cut'},
                {label: tr('Copy URL'), name: 'copyUrl', hide: this.selectList.length > 0 },
            ];
            return res;
        },
        menu2Options () {
            let res = [
                {label: tr('Paste'), name: 'paste'},
                {label: tr('Cut'), name: 'cut', hide: this.selectList.length > 0 },
                {label: tr('Remove Folder'), name: 'removeFolder', confirm: true, hide: this.selectList.length > 0 },
            ];
            return res;
        },
        canCreateFolder () {
            return tools.canAccess(this.user, 'api', 'GET', 'create_image_folder', true);
        },
        canUploadPhoto () {
            return tools.canAccess(this.user, 'api', 'GET', 'create_upload_photo', true);
        },
        getFolders () {
            return this.imageApi? this.imageApi.getFolders(this.search): [];
        },
        imageList () {
            if (!this.listMode) {
                return this.imageApi? this.imageApi.imageList(this.search): [];
            }
            let search = this.search;
            let files = [];
            if (this.imageApi.files) {
                if (this.imageApi.path) {
                    files = this.imageApi.files[this.imageApi.path];
                } else {
                    files = this.imageApi.files[''];
                }
            }
            if (search) {
                let res = [];
                let values = search.split(' ')
                for (let key in this.imageApi.files) {
                    let images = this.imageApi.files[key];
                    for (let r of images) {
                        let add = true;
                        for (let value of values){
                            let found = false;
                            let re = new RegExp(tools.normalize(value), 'i');
                            if (r.name) {
                                let m = tools.normalize(r.name).match(re);
                                if (m) found = true;
                            }
                            if (key) {
                                let m = tools.normalize(key).match(re);
                                if (m) found = true;
                            }
                            if (!found) add = false;
                        }
                        if (add) {
                            res.push({key, img: r});
                        }
                    }
                }
                return res;
            }
            return files;
        },
        getRouteName () {
            return this.imageApi? this.imageApi.path.replace('/', ' / '): '';
        },
    },
}
</script>

<style scoped>
.image-selected {
    border: #3283e7 solid 2px !important;
}
</style>