<script>
import abmComp from '@/components/tools/abm';
export default {
    name: 'abm-booking',
    props: ['endpoint', 'options', 'tableName', 'refresh', 'template', 'sales'],
    mixins: [abmComp],
    mounted () {
        this.customFilters.push(
            {name: 'ShowDue', editor: 'checkbox', label: 'Show Due', callback: () => {
                this.getAbm();
            }},
            {name: 'DateInnFrom', editor: 'date', label: 'Date Inn From'},
            {name: 'DateInnTo', editor: 'date', label: 'Date Inn To'},
        )
        this.filtersValues.DateInnFrom = null;
        this.filtersValues.DateInnTo = null;
        this.filtersValues.ShowDue = false;
    },
    computed: {
        list () {
            let res = this.abm.abmList();
            if (this.filtersValues.DateInnFrom) {
                res = _.filter(res, (r) => {
                    return r.StartDate >= this.filtersValues.DateInnFrom;
                })
            }
            if (this.filtersValues.DateInnTo) {
                res = _.filter(res, (r) => {
                    return r.StartDate <= this.filtersValues.DateInnTo;
                })
            }
            return res;
        },
    },
    methods: {
        getRouteName (currentRoute) {
            //let routeName = 'booking';
            let currentRouteName = currentRoute.name;
            if (this.$parent && this.$parent.$options.name == 'dashboard-card') {
                currentRouteName = this.$parent.card.currentRoute;
            }
            let routeName;
            if (currentRouteName == 'abm-booking') routeName = 'booking';
            if (currentRouteName == 'abm-packages') routeName = 'package';
            if (currentRouteName == 'abm-my-templates') routeName = 'my-template';
            if (currentRouteName == 'abm-private-templates') routeName = 'private-template';
            if (currentRouteName == 'abm-templates') routeName = 'template';
            if (currentRouteName == 'abm-sales') routeName = 'sale';
            if (currentRouteName == 'abm-my-sales') routeName = 'my-sale';
            if (currentRouteName == 'abm-my-files') routeName = 'my-file';
            if (currentRouteName == 'abm-my-booking') routeName = 'my-booking';
            if (!routeName) routeName = 'booking';
            return routeName;
        },
        async showRecord (c) {
            let routeName = this.getRouteName(this.$router.currentRoute);
            this.$store.commit('setCurrentTab', 'cover');
            this.$router.push({
                name: routeName,
                params: {
                    id: c.id.toString(),
                    sales: this.sales,
                    template: this.template,
                }
            });
        },
        applyFilters () {
            let endpoint = this.endpoint;
            if (this.filtersValues.ShowDue) {
                endpoint = `${this.endpoint}&Due=1`;
            }
            return endpoint
        },
        async getAbm () {
            let abmBooking = api.importMixinModule('booking', 'abms');
            let endpoint = this.applyFilters();
            this.abm = await new abmBooking({options: this.options, endpoint, template: this.template, sales: this.sales, table: this.tableName});
            if (!this.options.serverSide) await this.fetchList();
        },
        async showTemplate (c) {
            this.$router.push({
                name: 'booking',
                params: {
                    id: c.id.toString(),
                    sales: this.sales,
                    template: this.template,
                }
            });
        },
        addNew  () {
            let routeName = this.getRouteName(this.$router.currentRoute);
            this.$store.commit('setCurrentTab', 'cover');
            this.$router.push({
                name: routeName,
                params: {
                    table: 'booking',
                    id: 'new',
                    sales: this.sales,
                    template: this.template,
                }
            });
        },
        async createBooking (c) {
            let model = await api.importMixinModule('booking', 'model');
            let template = await model.get({id: c.id});
            let t = _.cloneDeep(template);
            let booking = await new model({isTemplate: false});
            await booking.createBookingFromTemplate(t);
            this.$store.commit('setModified', false);
            this.$router.push({ name: 'booking', params: {newBooking: booking, id: 'new',
                sales: this.sales}});
        },
    },
}
</script>